import React from "react"
import {Link} from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Animation from "../components/animation";

const IndexPage = () => (
    <Layout>
        <SEO title="Home"/>
        <div className="site">
            <div className="dot-1"></div>
            <div className="dot-2"></div>
            <div className="dot-3"></div>
            <div className="dot-4"></div>
            <div className="dot-5"></div>
            <div className="dot-6"></div>
            <div className="dot-7"/>
            <div className="dot-8"/>
            <div className="dot-9"></div>
            <div className="dot-10"></div>
            <div className="animation">
                <Animation/>
            </div>
            <div className="intro">
                <div>
                    <h1>
                        DigitalPaper<span>Green</span>
                    </h1>
                    <p>digital content publishing</p>
                    <p>
                        <Link to="contact">contact</Link>
                    </p>
                </div>

            </div>
        </div>
    </Layout>
)

export default IndexPage
