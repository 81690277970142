import React from "react";

function Moon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 525 525">
            <circle cx="256" cy="259.8" r="256" fill="#24863d"/>
            <circle cx="296.2" cy="255.7" r="48" fill="#24683d"/>
            <circle cx="77.1" cy="224.8" r="35.6" fill="#24683d"/>
            <circle cx="148.4" cy="116.7" r="31.4" fill="#24683d"/>
            <circle cx="95.3" cy="331.7" r="13.2" fill="#24683d"/>
            <circle cx="208.7" cy="375.7" r="13.2" fill="#24683d"/>
            <path fill="#24653d" d="M393 43A256 256 0 0140 396 256 256 0 10393 43z"/>
            <circle cx="286.3" cy="457.2" r="17.7" fill="#244c3d"/>
            <circle cx="338.4" cy="107.7" r="26.1" fill="#24683d"/>
            <circle cx="469.3" cy="273.4" r="26.1" fill="#244c3d"/>
        </svg>
    );
}

export default Moon;
